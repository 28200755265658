/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from "query-string";
import {
	getCategories,
	getCategoryBySlug,
} from "../fake-server/endpoints/categories";
import {
	getDiscountedProducts,
	getFeaturedProducts,
	getLatestProducts,
	getPopularProducts,
	getProductBySlug,
	getProductsList,
	getRelatedProducts,
	getSuggestions,
	getTopRatedProducts,
} from "../fake-server/endpoints/products";

const shopApi = {
	/**
	 * Returns array of categories.
	 *
	 * @param {object?} options
	 * @param {number?} options.depth
	 *
	 * @return {Promise<Array<object>>}
	 */
	getCategories: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/categories.json?depth=2
		 *
		 * where:
		 * - 2 = options.depth
		 */
		// return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getCategories(options);
	},
	/**
	 * Returns category by slug.
	 *
	 * @param {string} slug
	 * @param {object?} options
	 * @param {number?} options.depth
	 *
	 * @return {Promise<object>}
	 */
	getCategoryBySlug: async (slug, options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/categories/power-tools.json?depth=2
		 *
		 * where:
		 * - power-tools = slug
		 * - 2           = options.depth
		 */
		const response = await fetch(
			`https://backend.atlbha.sa/api/category/${slug}`
		);
		return await response.json();

		// This is for demonstration purposes only. Remove it and use the code above.
		//return getCategoryBySlug(slug, options);
	},
	/**
	 * Returns product.
	 *
	 * @param {string} slug
	 *
	 * @return {Promise<object>}
	 */
	getProductBySlug: async (slug) => {
		const domain = window?.location?.pathname?.split("/")[1];
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/products/screwdriver-a2017.json
		 *
		 * where:
		 * - screwdriver-a2017 = slug
		 */
		const response = await fetch(
			`https://backend.atlbha.sa/api/productPage/${domain}/${slug}`
		);
		return await response.json();

		// This is for demonstration purposes only. Remove it and use the code above.
		// return getProductBySlug(slug);
	},
	/**
	 * Returns array of related products.
	 *
	 * @param {string}  slug
	 * @param {object?} options
	 * @param {number?} options.limit
	 *
	 * @return {Promise<Array<object>>}
	 */
	getRelatedProducts: (slug, options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
		 *
		 * where:
		 * - screwdriver-a2017 = slug
		 * - limit             = options.limit
		 */
		// return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getRelatedProducts(slug, options);
	},
	/**
	 * Return products list.
	 *
	 * @param {object?} options
	 * @param {number?} options.page
	 * @param {number?} options.limit
	 * @param {string?} options.sort
	 * @param {Object.<string, string>?} filters
	 *
	 * @return {Promise<object>}
	 */
	getProductsList: async (options = {}, filters = {}) => {
		const domain = window?.location?.pathname?.split("/")[1];
		const price_from = filters?.price?.slice(0, filters?.price?.indexOf("-"));
		const price_to = filters?.price?.slice(
			Number(filters?.price?.indexOf("-")) + 1,
			filters?.price?.length
		);
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
		 *
		 * where:
		 * - page            = options.page
		 * - limit           = options.limit
		 * - sort            = options.sort
		 * - filter_category = filters.category
		 * - filter_price    = filters.price
		 */
		// const params = { ...options };
		//
		// Object.keys(filters).forEach((slug) => {
		//     params[`filter_${slug}`] = filters[slug];
		// });
		//
		const response = await fetch(
			`https://backend.atlbha.sa/api/storeProductCategory?domain=${domain}${
				options?.limit ? `&limit=${options?.limit}` : ""
			}${options?.sort ? `&sort=${options?.sort}` : ""}${
				options?.page ? `&page=${options?.page}` : ""
			}${filters?.category ? `&filter_category=${filters?.category}` : ""}${
				price_from ? `&price_from=${Number(price_from)}` : ""
			}${price_to ? `&price_to=${Number(price_to)}` : ""}`
		);
		return await response.json();

		// This is for demonstration purposes only. Remove it and use the code above.
		//return getProductsList(options, filters);
	},
	/**
	 * Returns array of featured products.
	 *
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getFeaturedProducts: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
		 *
		 * where:
		 * - 3           = options.limit
		 * - power-tools = options.category
		 */
		// return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getFeaturedProducts(options);
	},
	/**
	 * Returns array of latest products.
	 *
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getLatestProducts: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
		 *
		 * where:
		 * - 3           = options.limit
		 * - power-tools = options.category
		 */
		// return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getLatestProducts(options);
	},
	/**
	 * Returns an array of top rated products.
	 *
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getTopRatedProducts: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
		 *
		 * where:
		 * - 3           = options.limit
		 * - power-tools = options.category
		 */
		// return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getTopRatedProducts(options);
	},
	/**
	 * Returns an array of discounted products.
	 *
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getDiscountedProducts: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
		 *
		 * where:
		 * - 3           = options.limit
		 * - power-tools = options.category
		 */
		// return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getDiscountedProducts(options);
	},
	/**
	 * Returns an array of most popular products.
	 *
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getPopularProducts: (options = {}) => {
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
		 *
		 * where:
		 * - 3           = options.limit
		 * - power-tools = options.category
		 */
		// return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
		//     .then((response) => response.json());

		// This is for demonstration purposes only. Remove it and use the code above.
		return getPopularProducts(options);
	},
	/**
	 * Returns search suggestions.
	 *
	 * @param {string}  query
	 * @param {object?} options
	 * @param {number?} options.limit
	 * @param {string?} options.category
	 *
	 * @return {Promise<Array<object>>}
	 */
	getSuggestions: async (query, options = {}) => {
		const domain = window?.location?.pathname?.split("/")[1];
		/**
		 * This is what your API endpoint might look like:
		 *
		 * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
		 *
		 * where:
		 * - query    = query
		 * - limit    = options.limit
		 * - category = options.category
		 */
		const response = await fetch(
			`https://backend.atlbha.sa/api/productSearch?domain=${domain}&query=${query}${
				options?.category ? `&category=${options?.category}` : ""
			}`
		);
		return await response.json();

		// This is for demonstration purposes only. Remove it and use the code above.
		//return getSuggestions(query, options);
	},
};

export default shopApi;
