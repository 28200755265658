// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import { Cart16Svg } from "../../svg";
import { cartAddItem, cartAddItemLocal } from "../../store/cart";
import { getFilterAttributes, getOptionsId, getOptionsPrice, getOptionsStock } from "../../Utilities/UtilitiesFunctions";

function Suggestions(props) {
    const domain = window?.location?.pathname?.split("/")[1];
    const token = localStorage.getItem("token");
    const { context, className, products, cartAddItem, cartAddItemLocal } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const getOptions = (product) => {
        const attributesName = getFilterAttributes(product?.attributes)?.map((attribute) => {
            const filteredValues = attribute?.values?.filter((item) => item?.value?.[1] === "1");
            return filteredValues.length > 0 ? filteredValues : attribute?.values;
        });
        if (attributesName?.length > 0) {
            return attributesName?.map((attribute) => attribute?.[0]?.value?.[0]);
        } else {
            return [];
        }
    };

    const list =
        products &&
        products?.map((product) => (
            <li key={product?.id} className="suggestions__item">
                {product.cover && (
                    <div className="suggestions__item-image product-image">
                        <div className="product-image__body">
                            <img className="product-image__img" src={product.cover} alt={product.name} />
                        </div>
                    </div>
                )}
                <div className="suggestions__item-info">
                    <Link
                        className="suggestions__item-name"
                        to={`/${domain}/shop/product/${product?.id}/${encodeURIComponent(
                            product?.name.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-").toLowerCase()
                        )}`}
                    >
                        {product?.name}
                    </Link>
                </div>
                <div className="suggestions__item-price">
                    {Number(product?.discount_price) > 0 ? (
                        <React.Fragment>
                            <span className="suggestions__item-price-new">
                                <Currency value={Number(product?.discount_price) || 0} />
                            </span>
                            <span className="suggestions__item-price-old">
                                <Currency value={Number(product?.selling_price) || 0} />
                            </span>
                        </React.Fragment>
                    ) : (
                        <Currency value={Number(product?.selling_price) || 0} />
                    )}
                </div>
                {context === "header" && (
                    <div className="suggestions__item-actions">
                        {token ? (
                            <AsyncAction
                                action={() => cartAddItem(product, getOptionsId(product, getOptions(product)), 1, getOptionsPrice(product, getOptions(product)))}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        title="اضافة إلى السلة"
                                        className={classNames("btn btn-primary btn-sm btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                        disabled={product?.stock === "0"}
                                    >
                                        <Cart16Svg />
                                    </button>
                                )}
                            />
                        ) : (
                            <AsyncAction
                                action={() =>
                                    cartAddItemLocal(
                                        product,
                                        getOptions(product),
                                        1,
                                        domain,
                                        getOptionsPrice(product, getOptions(product)),
                                        getOptionsStock(product, getOptions(product))
                                    )
                                }
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        title="اضافة إلى السلة"
                                        className={classNames("btn btn-primary btn-sm btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                        disabled={product?.stock === "0"}
                                    >
                                        <Cart16Svg />
                                    </button>
                                )}
                            />
                        )}
                    </div>
                )}
            </li>
        ));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    cartAddItemLocal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
