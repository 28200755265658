import React from "react";
import useApiFetch from "../../hooks/useApiFetch";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import PageLoading from "../../components/PageLoading";

const SlideShow = ({ url }) => {
    const domain = window.location.pathname.split("/")[1];
    const { data, loading } = useApiFetch(`${url}/${domain}`);

    if (loading)
        return (
            <div className="block-slideshow block block-slideshow--layout--full">
                <div className="container">
                    <div className="block-slideshow__body">
                        <PageLoading classNames="style-2" />
                    </div>
                </div>
            </div>
        );

    const banars = Array.isArray(data?.data?.banars) ? data.data.banars : [];
    const sliders = Array.isArray(data?.data?.sliders) ? data.data.sliders : [];

    return (
        <div className="block-slideshow block block-slideshow--layout--full">
            <div className="container">
                <div className="block-slideshow__body">
                    <Swiper
                        spaceBetween={0}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="SlideShow w-100 h-100"
                    >
                        {banars.length === 0 ? null : (
                            <>
                                {banars.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={image}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </>
                        )}
                        {sliders.length === 0 ? null : (
                            <>
                                {sliders.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={image}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </>
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default SlideShow;
